import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import Link from "gatsby-link";

function CtaBar(){
    const data = useStaticQuery(graphql`
{
  allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Job_Title
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
  }
}



`)
    const WebsiteContent = data.allDataJson.edges[0].node

    return(
        <div className={" px-4 py-16 bg-main-color-500 dark:bg-main-color-700"}>

            <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>{"Need To Hire An Experienced " + WebsiteContent.Job_Title + "?"}</h1>
                <Link to={"/contact#contact-form"}>
                    <button className={"white hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full bg-white text-black mt-4 sm:mt-0 sm:ml-4"}>Request Free Estimate</button></Link>
            </div>

        </div>
    )
}
export default CtaBar