import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import ReviewSection from "../components/review-section";
import CtaBar from "../components/cta-bar";
import HeroImage from "../data/images/hero-reviews.jpg"
import BackgroundImageSelf from "../components/background-image-self";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
export const query = graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`

const Testimonials = ({data}) => {
    const WebsiteContent = data.allDataJson.edges[0].node

    return(
        <main>
            <Helmet>
                <title>{WebsiteContent.Business_Name + " | Reviews"}</title>
                <meta name="description" content={WebsiteContent.Tag_Line} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImageSelf image={HeroImage} mobileImage={HeroImage} alt={"forest background"} height={"h-128"} hero={true}>
                <h1 className={"text-6xl text-center text-white p-6"}>Testimonials</h1>
            </BackgroundImageSelf>
            <ReviewSection/>
            <CtaBarAlternate/>
            <Footer/>
        </main>
    )
}

export default Testimonials