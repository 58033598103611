import * as React from 'react'
import Link from "gatsby-link";

function Button(props){
    return(
        <Link to={props.to}>
        <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full " + props.inStyle}>
            {props.children}
        </button>
        </Link>
    )
}
export default Button